<template>
  <div v-if="plans">
    <header class="pb-8 max-w-screen-xl mx-auto flex justify-between items-center px-6">
      <h1 class="text-2xl leading-tight">
        Reactivate a plan
      </h1>
    </header>

    <div v-show="isProcessing">
      <Loader />
    </div>

    <div v-show="!isProcessing">
      <div class="max-w-screen-xl sm:flex mx-auto px-6">
        <BaseButton
          v-for="inactiveSubscription in inactiveSubscriptions"
          :key="inactiveSubscription.uuid"
          @click="reactivate(inactiveSubscription.uuid)"
        >
          Reactivate
          {{ plan(inactiveSubscription.planCode).name }}
          ({{ plan(inactiveSubscription.planCode).interval }}ly)
        </BaseButton>
      </div>
    </div>
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import Loader from '@components/Loader'

import { mapGetters } from 'vuex'

export default {
  components: {
    Loader
  },

  data() {
    return {
      isProcessing: false
    }
  },

  computed: {
    ...mapGetters({
      havePlansLoaded: 'plans/havePlansLoaded',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      plans: 'plans/tcPlans',
      hasActiveSubscription: 'subscriptions/hasActiveSubscription',
      activeSubscriptions: 'subscriptions/activeSubscriptions',
      inactiveSubscriptions: 'subscriptions/inactiveSubscriptions'
    })
  },

  created() {
    this.getPlans()
  },

  methods: {
    /**
     * Fetch the plans if we need to get them
     */
    getPlans() {
      if (this.havePlansLoaded) {
        this.getSubscriptions()
        return
      }
      this.$store.dispatch('plans/getPlans')
        .then(() => {
          this.getSubscriptions()
        })
    },

    /**
     * Fetch the subscriptions
     */
    getSubscriptions() {
      if (!this.haveSubscriptionsLoaded) {
        this.$store.dispatch('subscriptions/getSubscriptions')
      }
    },

    /**
     * @param {string} uuid
     */
    reactivate(uuid) {
      this.isProcessing = true
      this.$store.dispatch('subscriptions/reactivateSubscription', uuid)
        .then(() => {
          this.$router.push({ name: 'client-assessments-list' })
        })
    },

    /**
     * Returns a list of plan codes for current subscriptions
     *
     * @return {String}
     */
    plan(planSlug) {
      if (!this.plans) {
        return
      }
      return this.plans.find(plan => {
        return plan.slug === planSlug
      })
    }
  }
}
</script>
