<template>
  <div class="w-full py-8">
    <ReactivatePlan />
  </div>
</template>

<script>
import ReactivatePlan from '@components/Plans/ReactivatePlan'

export default {
  page: {
    title: 'Reactivate plan'
  },

  components: {
    ReactivatePlan
  }
}
</script>
